export const authSettings = {

  admin: {
    view: [
      'home', 
      'modulo-formularios', 'modulo-compras-comunitarias', 'modulo-legajos-digitales', 'modulo-registro-eventos', 'modulo-recepcion', 'modulo-control-flota-vehicular',
      'formularios-coordinador-general', 'aprobacion-formularios', 'formularios-administracion', 'formularios-administracion-compras', 'formularios-administracion-subsidio', 'formularios-administracion-contabilidad', 'formularios-administracion-rendiciones',
      'formularios-config', 'formularios-config-aerolineas', 'formularios-config-areas', 'formularios-config-departamentos', 'formularios-config-empresas-transporte', 'formularios-config-eventos', 'formularios-config-hoteles', 'formularios-config-pasajeros', 'formularios-config-resp-departamento', 'formularios-config-rubros',
    ],
    create: ['home', 
      'modulo-formularios', 'modulo-compras-comunitarias', 'modulo-legajos-digitales', 'modulo-registro-eventos', 'modulo-recepcion', 'modulo-control-flota-vehicular',
      'formularios-coordinador-general','coordinacion-general', 'aprobacion-formularios', 'formularios-administracion', 'formularios-administracion-compras', 'formularios-administracion-subsidio', 'formularios-administracion-contabilidad', 'formularios-administracion-rendiciones',
      'formularios-config', 'formularios-config-aerolineas', 'formularios-config-areas', 'formularios-config-departamentos', 'formularios-config-empresas-transporte', 'formularios-config-eventos', 'formularios-config-hoteles', 'formularios-config-pasajeros', 'formularios-config-resp-departamento', 'formularios-config-rubros',],
    edit: ['home', 
      'formularios-coordinador-general','modulo-formularios', 'modulo-compras-comunitarias', 'modulo-legajos-digitales', 'modulo-registro-eventos', 'modulo-recepcion', 'modulo-control-flota-vehicular',
      'coordinacion-general', 'aprobacion-formularios', 'formularios-administracion', 'formularios-administracion-compras', 'formularios-administracion-subsidio', 'formularios-administracion-contabilidad', 'formularios-administracion-rendiciones',
      'formularios-config', 'formularios-config-aerolineas', 'formularios-config-areas', 'formularios-config-departamentos', 'formularios-config-empresas-transporte', 'formularios-config-eventos', 'formularios-config-hoteles', 'formularios-config-pasajeros', 'formularios-config-resp-departamento', 'formularios-config-rubros',],
    remove: ['home', 
      'formularios-coordinador-general','modulo-formularios', 'modulo-compras-comunitarias', 'modulo-legajos-digitales', 'modulo-registro-eventos', 'modulo-recepcion', 'modulo-control-flota-vehicular',
      'coordinacion-general', 'aprobacion-formularios', 'formularios-administracion', 'formularios-administracion-compras', 'formularios-administracion-subsidio', 'formularios-administracion-contabilidad', 'formularios-administracion-rendiciones',
      'formularios-config', 'formularios-config-aerolineas', 'formularios-config-areas', 'formularios-config-departamentos', 'formularios-config-empresas-transporte', 'formularios-config-eventos', 'formularios-config-hoteles', 'formularios-config-pasajeros', 'formularios-config-resp-departamento', 'formularios-config-rubros',],
  },

  guest: {
  },

  // Accesos a los módulos.

  'miembro-cnbvra': {
    view: ['home'],
  },

  'modulo-formularios': {
    view: ['modulo-formularios'],
  },

  'modulo-compras-comunitarias': {
    view: ['modulo-compras-comunitarias'],
  },

  'modulo-legajos-digitales': {
    view: ['modulo-legajos-digitales'],
  },

  'modulo-registro-eventos': {
    view: ['modulo-registro-eventos'],
  },

  'modulo-recepcion': {
    view: ['modulo-recepcion'],
  },

  'modulo-control-flota-vehicular': {
    view: ['modulo-control-flota-vehicular'],
  },

  // Permisos dentro del módulo de formularios.

  'formularios-coordinador-general': {
    view: ['formularios-coordinador-general'],
    create: ['formularios-coordinador-general'],
    edit: ['formularios-coordinador-general'],
    remove: ['formularios-coordinador-general'],
  },

  'formularios-coordinador': {
    view: ['formularios-coordinador'],
    create: ['formularios-coordinador'],
    edit: ['formularios-coordinador'],
  },

  'formularios-administracion': {
    view: ['formularios-administracion','formularios-administracion-compras','formularios-administracion-subsidio','formularios-administracion-contabilidad','formularios-administracion-rendiciones'],
  },

  'formularios-administracion-compras': {
    view: ['formularios-administracion-compras'],
    create: ['formularios-administracion-compras'],
    edit: ['formularios-administracion-compras'],
  },

  'formularios-administracion-subsidio': {
    view: ['formularios-administracion-subsidio'],
    create: ['formularios-administracion-subsidio'],
    edit: ['formularios-administracion-subsidio'],
  },

  'formularios-administracion-contabilidad': {
    view: ['formularios-administracion-contabilidad'],
    create: ['formularios-administracion-contabilidad'],
    edit: ['formularios-administracion-contabilidad'],
  },

  'formularios-administracion-rendiciones': {
    view: ['formularios-administracion-rendiciones'],
    create: ['formularios-administracion-rendiciones'],
    edit: ['formularios-administracion-rendiciones'],
  },

  'formularios-administracion-resoluciones': {
    view: ['formularios-administracion-resoluciones'],
    create: ['formularios-administracion-resoluciones'],
    edit: ['formularios-administracion-resoluciones'],
  },

  'formularios-administracion-convenios': {
    view: ['formularios-administracion-convenios'],
    create: ['formularios-administracion-convenios'],
    edit: ['formularios-administracion-convenios'],
  },

  'formularios-config': {
    view: ['formularios-config'],
  },

  'formularios-config-aerolineas': {
    view: ['formularios-config-aerolineas'],
    create: ['formularios-config-aerolineas'],
    edit: ['formularios-config-aerolineas'],
  },

  'formularios-config-areas': {
    view: ['formularios-config-areas'],
    create: ['formularios-config-areas'],
    edit: ['formularios-config-areas'],
  },

  'formularios-config-departamentos': {
    view: ['formularios-config-departamentos'],
    create: ['formularios-config-departamentos'],
    edit: ['formularios-config-departamentos'],
  },
  
  'formularios-config-empresas-transporte': {
    view: ['formularios-config-empresas-transporte'],
    create: ['formularios-config-empresas-transporte'],
    edit: ['formularios-config-empresas-transporte'],
  },

  'formularios-config-eventos': {
    view: ['formularios-config-eventos'],
    create: ['formularios-config-eventos'],
    edit: ['formularios-config-eventos'],
  },

  'formularios-config-hoteles': {
    view: ['formularios-config-hoteles'],
    create: ['formularios-config-hoteles'],
    edit: ['formularios-config-hoteles'],
  },

  'formularios-config-pasajeros': {
    view: ['formularios-config-pasajeros'],
    create: ['formularios-config-pasajeros'],
    edit: ['formularios-config-pasajeros'],
  },

  'formularios-config-resp-departamento': {
    view: ['formularios-config-resp-departamento'],
    create: ['formularios-config-resp-departamento'],
    edit: ['formularios-config-resp-departamento'],
  },

  'formularios-config-rubros': {
    view: ['formularios-config-rubros'],
    create: ['formularios-config-rubros'],
    edit: ['formularios-config-rubros'],
  },
};
